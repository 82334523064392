import request from '@/utils/request'

/** 分页获取打卡信息 */
export  function getPageList(params) {
    return request({
        url: '/delivery/driver/clock/getPageList',
        method: 'GET',
        params: params
    })
}

export function remove(guid) {
    return request({
        url: '/delivery/driver/clock/del/'+guid,
        method: 'DELETE'
    })
}

export function deletes(ids) {
    return request({
        url: '/delivery/driver/clock/removes',
        method: 'DELETE',
        data: ids
    })
}

export function getPageImg(params) {
    return request({
        url: '/delivery/driver/clock/getPageImg',
        method: 'GET',
        params: params
    })
}


export function getNotClockDriver(params) {
    return request({
        url: '/delivery/driver/clock/nowNoClockDriver',
        method: 'GET',
        params: params
    })
}

export function exportExcel(data) {
    return request({
        url: '/delivery/driver/clock/exportExcel',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}
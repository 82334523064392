<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouseId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.createBy" placeholder="请选择司机" clearable filterable>
                    <el-option v-for="(item, index) in drivers" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        :editable="false"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        v-model="queryParams.createTime"
                        placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>

         <el-row :gutter="15" class="mb20">
             <el-col :span="24">
                 <el-button
                         type="danger"
                         icon="el-icon-delete"
                         :size="$store.getters.size"
                         v-if="$app.haveRole('driver:clock:deletes')"
                         :disabled="multipleSelection.length <= 0"
                         @click="handleDeletes"
                 >批量删除</el-button>
             </el-col>
         </el-row>

        <el-table :data="tableData" :size="$store.getters.size" v-loading="loading"
                  @selection-change="handleSelectionChange" row-key="guid" ref="table">
            <el-table-column type="expand" align="center" width="1">
                <template slot-scope="scope">
                    <el-form label-width="100px" :size="$store.getters.size" class="record_form">
                        <el-form-item label="司机姓名">{{scope.row.driverName}}</el-form-item>
                        <el-form-item label="打卡时间">{{scope.row.createTime}}</el-form-item>
                        <el-form-item label="车牌号">{{scope.row.vehicle}}</el-form-item>
                        <el-form-item label="车号">{{scope.row.plat}}</el-form-item>
                        <el-form-item label="打卡地点">{{scope.row.clockAddr}}</el-form-item>
                        <el-form-item label="打卡图片"><el-image :src="scope.row.clockImg" @click="imageView(scope.row.clockImg)"/></el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="司机姓名" prop="driverName" width="350"></el-table-column>
            <el-table-column label="打卡时间" prop="createTime" width="350" align="center"></el-table-column>
            <el-table-column label="打卡地点" prop="clockAddr" width="" ></el-table-column>
            <el-table-column label="操作" width="350">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                               v-if="$app.haveRole('driver:clock:delete')">删除</el-button>
                    <el-button type="text" @click="toogleExpand(scope.row)">{{scope.row.expansion ? '收起' : '查看详情'}}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <page-item :total="total" ref="pageItem"/>
        <image-view ref="imageView"/>
    </div>
</template>


<script>
    import PageItem from '@/components/PageItem/'
    import ImageView from '@/components/ImageView/'
    import {getAllDriver} from '@/api/system/driver'
    import {getPageList,remove,deletes} from "@/api/delivery/driver_clock";

    export default {
        name: "DriverClock",
        components: {PageItem, ImageView},
        data(){
            return{
                queryParams: {
                    warehouseId: undefined,
                    createTime: undefined,
                    createBy: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                drivers: [],
                tableData: [],
                total: 0,
                loading: false,
                multipleSelection: []
            }
        },
        methods: {
            getTableData(){
                if(!this.queryParams.warehouseId){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getPageList(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            imgUrlSplit(urls){
                if(urls) {
                    return urls.split(",");
                }else{
                    return [];
                }
            },
            imageView(urlStr, index){
                this.$refs['imageView'].index = index;
                this.$refs['imageView'].urls = this.imgUrlSplit(urlStr);
                this.$refs['imageView'].isShow = true;
            },
            handleDelete(row){
                this.$confirm("你确定要删除此数据?","提示",{
                    type: "warning"
                }).then(() => {
                    remove(row.guid).then(({code})=>{
                        if(code === 0){
                            this.$message.success("删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            toogleExpand(row){
                let $table = this.$refs.table;
                this.tableData.map(item => {
                    if (row.guid != item.guid) {
                        $table.toggleRowExpansion(item, false)
                        item.expansion = false
                    } else {
                        item.expansion = !item.expansion
                    }
                })
                $table.toggleRowExpansion(row)
            },

            handleDeletes(){
                if(this.multipleSelection.length<=0){
                    this.$message.error("未选择需要删除数据!");
                    return false;
                }
                this.$confirm("你确定要删除选中的数据?","提示",{
                    type: "warning"
                }).then(() => {
                    const ids = this.multipleSelection.map(e => e.guid)
                    deletes(ids).then(({code}) => {
                        if(code === 0){
                            this.$message.success("批量删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=> {})
            },

        },
        watch: {
            'queryParams.warehouseId': function (val, oldVal) {
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.drivers = data;
                        }
                    })
                }
            }
        }
    }

</script>



<style lang="scss" scoped>
    .record_form {
        .el-image {
            margin: 10px;
            width: 110px;
            height: 110px;
        }
        .el-image:hover{
            border: 1px solid #EBEEF5;
        }
    }
</style>
